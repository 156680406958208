import * as Axios from 'axios';

import isAuthenticated from '../auth/auth';
import { isStatic } from '../common/isStatic';


const API_URL_CLIENT = process.env.REACT_APP_API_LINK + "/client-application";
const API_URL = process.env.REACT_APP_API_LINK;
// const API_URL_CLIENT = "http://localhost:4000/client"
const CLOUD_API = "https://api.cloudinary.com/v1_1/ddujllgru/image/upload";



const axios = Axios.create({
    'headers': { 'x-api-key': process.env.REACT_APP_X_API_KEY }
})
window.name = "Success"
//posting code requet to api
export const initialRegister = async (data) => {
    const res = await axios.post(`${API_URL_CLIENT}/initial-register`, data);
    return res.data;
}

//registering new code
export const fullRegister = async (data) => {
    const res = await axios.post(`${API_URL_CLIENT}/full-register`, data);
    return res.data;
}

//google signup
export const googleSignup = async (data) => {
    const res = await axios.post(`${API_URL_CLIENT}/google-signup`, data);
    return res.data;
}

//customer support new code
export const customerSupport = async (data) => {
    const res = await axios.post(`${API_URL_CLIENT}/customer-support`, data);
    return res.data;
}


//updating the profile/user
export const userUpdate = async (data) => {
    const id = data.id
    delete data.id
    const res = await axios.put(`${API_URL}/user/${id}`, data,
        {
            headers: {
                'Authorization': `Stickball ${localStorage.getItem('token')}`
            }
        }
    );
    return res.data;
}
export const getUsers = async (clas) => {
    const res = await axios.get(`${API_URL_CLIENT}/journal/user`,
        {
            headers: {
                'Authorization': `Stickball ${localStorage.getItem('token')}`
            }
        }
    );
    return res.data;
}

export const getOrganizations = async (clas) => {
    if (isStatic) {
        return []
    } else {
        const res = await axios.get(`${API_URL}/organization`,
            {
                headers: {
                    'Authorization': `Stickball ${localStorage.getItem('token')}`
                }
            }
        );
        return res.data;
    }

}

export const getStudentData = async () => {
    let URL = "assets/json/student_data.json"
    try {
        const res = await axios.get(URL,
            {
                headers: {
                    'Authorization': `Stickball ${localStorage.getItem('token')}`
                }
            })
        return (res.data)
    } catch (error) {
        console.log("Error: ", error)
    }
}


//verifying email address
export const emailVerification = async (data) => {
    const res = await axios.post(`${API_URL_CLIENT}/verify-email-code`, data)
    return (res.data)
}

//posting register request to api
export const register = async (data) => {
    const res = await axios.post(`${API_URL_CLIENT}/code-register`, data);
    return res.data;
}

//resending code
export const resendCode = async (data) => {
    const res = await axios.post(`${API_URL_CLIENT}/resend-email-verification-code`, data);
    return res.data;
}

//code reset posting to api
export const resetCode = async (data) => {
    const res = await axios.post(`${API_URL_CLIENT}/send-password-reset-code`, data);
    return res.data;
}

//reset Password
export const resetPassword = async (data) => {
    const res = await axios.post(`${API_URL_CLIENT}/reset-password`, data);
    return res.data;
}

//change Password
export const changePassword = async (data) => {
    const res = await axios.post(`${API_URL_CLIENT}/change-password`, data, {
        headers: {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    })
    return res.data;
}


//verify Password
export const verifyOTP = async (data) => {
    const res = await axios.post(`${API_URL_CLIENT}/verify-otp`, data);
    return res.data;
}

//posting login request to the api
export const login = async (data) => {
    const res = await axios.post(`${API_URL_CLIENT}/login`, data,);
    return res.data;
}

//posting clever login request to the api
export const cleverLogin = async (data) => {
    const res = await axios.post(`${API_URL_CLIENT}/clever-login`, data,
        {
            method: 'Post',
            withCredentials: true
        }
    );
    return res.data;
}

//posting clever register request to the api
export const cleverRegister = async (data) => {
    const res = await axios.post(`${API_URL_CLIENT}/clever-register`, data,
        {
            method: 'Post',
            withCredentials: true
        }
    );
    return res.data;
}

//uploading image to the cloudnary
export const uploadImage = async (data) => {
    const res = await axios.post(`${CLOUD_API}`, data);
    return res.data;
}


export const getJobs = async () => {
    if (isAuthenticated(localStorage.getItem("token")) === true) {
        const res = await axios.get(`${API_URL_CLIENT}/jobs/all-data`,
            {
                headers: {
                    'Authorization': `Stickball ${localStorage.getItem('token')}`
                }
            }
        )
        return (res.data)
    }
    else {
        return [];
    }
}


export const getAllData = async () => {
    if (isAuthenticated(localStorage.getItem("token"))) {
        window.name = "Success"
        let URL = isStatic ? "assets/json/english.json" : `${API_URL}/all-data-client`
        try {
            const res = await axios.get(URL,
                {
                    headers: {
                        'Authorization': `Stickball ${localStorage.getItem('token')}`
                    }
                })
            return (res.data)
        } catch {
            window.name = "Error"
        }
    }
    else {
        return [];
    }
}

export const newEnrollment = async () => {
    let token = localStorage.getItem('token')
    try {
        const res = await axios.get(`${API_URL}/enrollment/user/new-enrollment`,
            {
                headers: {
                    'Authorization': `Stickball ${token}`
                }
            }
        );
        return res.data;
    } catch {
        window.name = true
    }
}

export const adminEnrolledData = async () => {
    try {
        const res = await axios.get(`${API_URL}/all-data-client/admin`,
            {
                headers: {
                    'Authorization': `Stickball ${sessionStorage.getItem('token')}`
                }
            }
        );
        return res.data;
    } catch {
        window.name = true
    }
}


export const doEnrollment = async (data) => {
    let token = localStorage.getItem('token')
    try {
        const res = await axios.post(`${API_URL}/enrollment/user/continue-enrollment`, data,
            {
                headers: {
                    'Authorization': `Stickball ${token}`
                }
            }
        );
        return res.data;
    } catch (err) {
        window.name = true
        return err
    }
}

export const userLog = async (data) => {
    if (isStatic) {
        return []
    }
    const res = await axios.post(`${API_URL}/user-logs/user`, data,
        {
            headers: {
                'Authorization': `Stickball ${localStorage.getItem('token')}`
            }
        }
    );
    return res.data;
}

export const getSections = async (skillId) => axios.get(`${API_URL}/skill-section/populate/${skillId}`);

export const getForms = () => {
    return axios
        .get(API_URL + "/form", {
            headers: {
                'Authorization': `Stickball ${localStorage.getItem('adminToken')}`
            }
        })
        .then(response => {
            return response.data;
        })
}


export const getAllChatbots = () => {
    return axios
        .get(API_URL + "/chat-bot", {
            params: { limit: 1000, offset: 0 },
            headers: {
                'Authorization': `Stickball ${localStorage.getItem('adminToken')}`
            }
        })
        .then(response => {
            return response.data;

        })
}

export const getJotForms = () => {
    return axios
        .get(API_URL + "/jotform/forms", {
            params: { limit: 1000, offset: 0 },
            headers: {
                'Authorization': `Stickball ${localStorage.getItem('adminToken')}`
            }
        })
        .then(response => {
            return response.data;

        })
}

export const getAllIcons = async (iconToAdd) => {
    const res = await axios.get(`${API_URL}/icon`,
        {
            headers: {
                'Authorization': `Stickball ${localStorage.getItem('adminToken')}`
            }
        });
    return res.data;
};