import { createSlice } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';

const initialState = {
    waitTime: 20,
    userData: [],
    skillSections: [],
    quiz: {},
    moduleId: "",
    lessonId: "",
    skillId: "",
    skillName: "",
    subSkillId: "",
    subSkillName: "",
    session: "",
    quizPoints: 0,
    loading: false,
    error: false,
    moduleName: "",
    correctLetters: [],
    existLetters: [],
    absentLetters: [],
    words: [],
    chatbot: {},
    register: false,
    lesson: {},
    module: {},
    skill: {},
    isNextEnabled: false,
    hasWaitTime: true,
    notifications: [],
    notes: [],
    completedModules: [],
    completedLessons: [],
    completedSkills: [],
    completedSubSkills: [],
    skillCount: 0,
    skillIndex: null,
    lessonIndex: null,
    theme: {}
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setRegister: (state, action) => {
            state.register = action.payload
        },
        setData: (state, action) => {
            state.theme = action.payload.theme
            state.userData = action.payload
            state.loading = false
            state.userData?.data?.map(module => {
                if (module.id == state.moduleId) {
                    state.module = module
                    module?.lessons?.map(lesson => {
                        if (lesson?.id == state.lessonId) {
                            state.lesson = lesson
                            if (lesson.isCompleted) {
                                state.words = lesson?.words
                            }
                            lesson?.skills?.map(skill => {
                                if (skill.id == state.skillId) {
                                    state.skillName = skill?.name;
                                    state.skill = skill
                                    skill?.subSkills?.map(subSkill => {
                                        if (subSkill.id == state.subSkillId) {
                                            state.subSkillName = subSkill?.name;

                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        },
        setSkillSections: (state, action) => {
            let temp = []
            state.quiz = action.payload.quiz
            if (action.payload.skillSections[0].isCompleted) {
                state.skillSections = action.payload.skillSections
            } else {
                action.payload.skillSections.map((ss, i) => {
                    if (i === 0) {
                        temp.push({
                            id: ss.id,
                            name: ss.name,
                            allContent: ss.allContent,
                            isEnrolled: true,
                            isCompleted: false,
                        })
                    } else {
                        temp.push({
                            id: ss.id,
                            name: ss.name,
                            allContent: ss.allContent,
                            isEnrolled: false,
                            isCompleted: false,
                        })
                    }
                })
                state.skillSections = temp;
            }

        },
        setIDs: (state, action) => {
            state.moduleId = action.payload.module
            state.lessonId = action.payload.lesson
            state.skillId = action.payload.skill
            state.subSkillId = action.payload.subSkill
        },
        setSession: (state, action) => {
            state.session = action.payload
        },
        setSkillName: (state, action) => {
            state.skillName = action.payload
        },
        setSubSkillName: (state, action) => {
            state.subSkillName = action.payload
        },
        setQuizPoints: (state, action) => {
            state.quizPoints += action.payload
        },
        emptyPoints: (state) => {
            state.quizPoints = 0
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setModuleName: (state, action) => {
            state.moduleName = action.payload
        },
        setLessonName: (state, action) => {
            state.lessonName = action.payload
        },
        setCorrectLetters: (state, action) => {
            let index = state.correctLetters.findIndex(x => x === action.payload)
            if (index === -1) {
                state.correctLetters = [...state.correctLetters, action.payload]
            }

        },
        setExistLetters: (state, action) => {
            let index = state.existLetters.findIndex(x => x === action.payload)
            if (index === -1) {
                state.existLetters = [...state.existLetters, action.payload]
            }
        },
        setAbsentLetters: (state, action) => {
            let index = state.absentLetters.findIndex(x => x === action.payload)
            if (index === -1) {
                state.absentLetters = [...state.absentLetters, action.payload]
            }

        },
        removeAllLetters: state => {
            state.correctLetters = []
            state.existLetters = []
            state.absentLetters = []
        },
        setWords: (state, action) => {
            state.words = action.payload
        },
        setChatbot: (state, action) => {
            state.chatbot = action.payload
        },
        userEnroll: (state, action) => {
            let temp = []
            let index = false
            state.skillSections.map((ss, i) => {
                if (ss.id == action.payload) {
                    index = true;
                    temp.push({
                        id: ss.id,
                        name: ss.name,
                        allContent: ss.allContent,
                        isEnrolled: true,
                        isCompleted: true,
                    })
                } else {
                    if (index) {
                        temp.push({
                            id: ss.id,
                            name: ss.name,
                            allContent: ss.allContent,
                            isEnrolled: true,
                            isCompleted: false,
                        })
                        index = false
                    } else {
                        temp.push(ss)
                    }
                }
            })
            state.skillSections = temp;
        },
        emptyStore: (state) => {
            state.userData = [];
            state.skillSections = [];
            state.quiz = {};
            state.moduleId = "";
            state.lessonId = "";
            state.skillId = "";
            state.skillName = "";
            state.subSkillId = "";
            state.subSkillName = "";
            state.session = "";
            state.quizPoints = 0;
            state.loading = false;
            state.error = false;
            state.moduleName = "";
            state.correctLetters = [];
            state.existLetters = [];
            state.absentLetters = [];
            state.words = [];
            state.chatbot = {};
            state.register = false;
            state.lesson = {};
            state.module = {};
            state.skill = {};
            state.skillCount = 0
        },
        setNextEnabled: (state, action) => {
            state.isNextEnabled = action?.payload
        },
        setOrganization: (state, action) => {
            let organizations = action.payload
            let user = state.userData?.user
            if (!user || !organizations?.length) {
                return;
            }
            let organization = {}
            organizations?.map(org => {
                if (org?.id == user?.organizationId) {
                    organization = org
                }
            })
            if (user?.id == organization?.testing_user || organization?.waitTime == 0) {
                state.hasWaitTime = false
            } else {
                state.hasWaitTime = true
            }
            // state.waitTime = organization?.waitTime
            state.waitTime = 30

        },
        setNotifications: (state, action) => {
            let tempNotifications = []
            let notifications = action.payload?.reverse()
            notifications?.map(item => {
                if (state.notifications?.length) {

                    let fn = state.notifications.find(noti => noti.id == item.id)
                    if (fn) {
                        tempNotifications.push({
                            ...item,
                            status: fn?.status
                        })
                    } else {
                        tempNotifications.push({
                            ...item,
                            status: 'unread'
                        })
                    }
                } else {
                    tempNotifications.push({
                        ...item,
                        status: 'unread'
                    })
                }
            })
            state.notifications = [...tempNotifications]

        },
        setAllAsReadNotification: (state) => {
            let tempNotifications = []
            state.notifications?.map(item => {
                tempNotifications.push({
                    ...item,
                    status: 'read'
                })
            })
            state.notifications = [...tempNotifications]
        },
        setAsReadNotification: (state, action) => {
            let tempNotifications = []
            state.notifications?.map(item => {
                if (action.payload === item.id) {
                    tempNotifications.push({
                        ...item,
                        status: 'read'
                    })
                } else {
                    tempNotifications.push(item)
                }
            })
            state.notifications = [...tempNotifications]
        },
        setAsUnReadNotification: (state, action) => {
            let tempNotifications = []
            state.notifications?.map(item => {
                if (action.payload === item.id) {
                    tempNotifications.push({
                        ...item,
                        status: 'unread'
                    })
                } else {
                    tempNotifications.push(item)
                }
            })
            state.notifications = [...tempNotifications]
        },
        addNote: (state, action) => {
            let notes = state.notes || []
            notes.push(action.payload)
            state.notes = notes
        },
        updateNote: (state, action) => {
            let notes = []
            state.notes.map(note => {
                if (note._id === action.payload._id) {
                    notes.push(action.payload)
                } else {
                    notes.push(note)
                }
            })
            state.notes = notes
        },
        deleteNote: (state, action) => {
            let notes = state.notes || []
            notes = notes.filter(note => note._id !== action.payload)
            state.notes = notes
        },
        markCompleted: (state, action) => {
            let completedModules = state.completedModules || []
            let completedLessons = state.completedLessons || []
            let completedSkills = state.completedSkills || []
            let completedSubSkills = state.completedSubSkills || []
            const sub_skill_id = action.payload
            let modules = state.userData.data
            completedSubSkills.push(sub_skill_id)
            modules.map((module) => {
                module?.lessons?.map((lesson, lessonIndex) => {
                    lesson.skills.map((skill, skillIndex) => {
                        skill.subSkills.map((subSkill, index) => {
                            if (subSkill.id == sub_skill_id) {
                                let completedSubSkillsInSkill = skill.subSkills?.filter(item => completedSubSkills.includes(item.id)) || []
                                if (completedSubSkillsInSkill.length == skill.subSkills.length) {
                                    completedSkills.push(skill.id)
                                }

                                let completedSkillsInLesson = lesson.skills?.filter(item => completedSkills.includes(item.id)) || []
                                if (lesson.skills?.length == completedSkillsInLesson.length) {
                                    completedLessons.push(lesson.id)

                                    let completedLessonsInModule = module.lessons?.filter(item => completedLessons.includes(item.id)) || []
                                    if (module.lessons?.length == completedLessonsInModule.length) {
                                        completedModules.push(module.id)
                                    }
                                }
                            }
                        })
                    })
                })
            })
            state.completedModules = completedModules
            state.completedLessons = completedLessons
            state.completedSkills = completedSkills
            state.completedSubSkills = completedSubSkills
        },
        setSkillCount: (state, action) => {
            state.skillCount = action.payload
        },
        setLessonIndex: (state, action) => {
            state.lessonIndex = action.payload
        },
        setSkillIndex: (state, action) => {
            state.skillIndex = action.payload
        }

    },

})

export const {
    setNextEnabled,
    emptyStore,
    userEnroll,
    setSubSkillName,
    emptyPoints,
    setSkillName,
    setData,
    setSkillSections,
    setIDs,
    setSession,
    setQuizPoints,
    setLoading,
    setModuleName,
    setCorrectLetters,
    setExistLetters,
    setAbsentLetters,
    removeAllLetters,
    setWords,
    setChatbot,
    setRegister,
    setOrganization,
    setNotifications,
    setAllAsReadNotification,
    setAsReadNotification,
    setAsUnReadNotification,
    addNote,
    updateNote,
    setLessonName,
    deleteNote,
    markCompleted,
    setSkillCount,
    setLessonIndex,
    setSkillIndex
} = userSlice.actions

export default userSlice.reducer